import React, { useState } from "react";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import {
  handleChangeFormInput,
  setFormErrors,
  toggleImageUploadModal,
} from "../../../../../redux/kyc-form/actions";
import PaymentList from "../../paymentlist";
import OfflinePayment from "../../offlinepayment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CASH from "../../../../../assets/images/cash.png";
import { Nav, NavItem, NavLink } from "reactstrap";

const FormStep10 = (props) => {
  const {
    errors,
    selectedPlan,
    submitdata,
    setSelectedPaymentId,
    setShowPayment,
    showPayment,
    isEmailShow,
    istelShow,
    iswhatsShow,
  } = props;

  const [paymentRadiovalue, setPaymentRadiovalue] = useState("Offline");

  function checkEmptyValue(e) {
    if (e.target.value === "") {
      e.target.classList.remove("not-empty");
    } else {
      e.target.classList.add("not-empty");
    }
  }

  const totalGSTS =
    props?.selectedPlan?.plan_cgst === 0 && props.isShow === true
      ? props?.selectedPlan?.total_plan_cost * 0.18
      : 0;

  let totalPayableAmount =
    parseFloat(selectedPlan.final_total_plan_cost) +
    parseFloat(props.formData.installation_charges) +
    parseFloat(props.formData.security_deposit) +
    parseFloat(
      props.staticIPCost?.cost_per_ip ? props.staticIPCost?.cost_per_ip : 0
    ) *
    selectedPlan.time_unit +
    totalGSTS;

  const withoutStaticCost =
    parseFloat(props.totalAmountCal?.amount) +
    parseFloat(props.formData?.installation_charges) +
    parseFloat(props.formData?.security_deposit);

  const basedOnstatic = withoutStaticCost;

  const [BasicLineTab, setBasicLineTab] = useState("3");

  return (
    <React.Fragment>
      <Box>
        <Grid>
          <Nav className="border-tab1" tabs>
            <NavItem>
              <NavLink>{"PERSONAL DETAILS"}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink>{"SERVICE DETAILS"}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#javascript"
                className={BasicLineTab === "3" ? "active" : ""}
                onClick={() => setBasicLineTab("3")}
              >
                {"PAYMENT OPTIONS"}
              </NavLink>
            </NavItem>
          </Nav>
        </Grid>
      </Box>
      <Row>
        <Col sm="12" style={{ display: "flex" }}>
          <p className="form-heading-style" style={{ marginTop: "7px" }}>
            {"Payment Details"}
          </p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm="3">
          <FormGroup>
            <div className="input_wrap">
              <Label className="kyc_label">Total Payable Amount</Label>
              {console.log(props.formData, 'props.formdata')}
              <Input
                className={`form-control ${props.formData && !props.formData.plan_cost ? "" : "not-empty"
                  }`}
                type="number"
                name="plan_cost"
                onChange={({ name, value }) =>
                  handleChangeFormInput({
                    name,
                    value,
                    parent: null,
                  })
                }
                onBlur={checkEmptyValue}
                disabled={true}
                value={
                  props.formData.service_type === "OTT" &&
                    props.formData.OTT_total_plan_cost
                    ? parseFloat(props.formData.OTT_total_plan_cost).toFixed(0)
                    : props.formData.service_type === "COMBO" &&
                      props.formData.combo_total_plan_cost
                      ? Number(
                        parseFloat(props.formData.combo_total_plan_cost).toFixed(0)
                      ) +
                      (parseFloat(props.formData?.installation_charges) || 0) +
                      (parseFloat(props.formData?.security_deposit) || 0) +
                      (parseFloat(props.formData?.static_ip_cost) || 0)
                      : parseFloat(basedOnstatic).toFixed(0)
                }
                min="0"
                onKeyDown={(evt) =>
                  (evt.key === "e" ||
                    evt.key === "E" ||
                    evt.key === "." ||
                    evt.key === "-") &&
                  evt.preventDefault()
                }
              />
            </div>
            <span className="errortext">
              {errors.total_plan_cost && "Field is required"}
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="payment_method">Payment Method</p>
        </Col>
      </Row>
      <Row style={{ marginLeft: "0.5rem" }}>
        <Col sm="4">
          <FormGroup>
            <div className="input_wrap">
              <div className="radio radio-primary">
                <Input
                  id="radio11"
                  type="radio"
                  name="radio1"
                  value="option1"
                  checked={paymentRadiovalue === "send to phone"}
                  onClick={(e) => {
                    if (e.target.checked) setPaymentRadiovalue("send to phone");
                  }}
                />
                <Label for="radio11">
                  <span style={{ color: "#2b2b2b" }}>Online</span>
                </Label>
              </div>
            </div>
          </FormGroup>
        </Col>
        <Col sm="4">
          <FormGroup>
            <div className="input_wrap">
              <div className="radio radio-primary">
                <Input
                  id="radio33"
                  type="radio"
                  name="radio1"
                  value="option1"
                  defaultChecked
                  checked={paymentRadiovalue === "Offline"}
                  onClick={() => {
                    setPaymentRadiovalue("Offline");
                  }}
                />
                <Label for="radio33">
                  <span style={{ color: "#2b2b2b" }}>Offline</span>
                </Label>
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {paymentRadiovalue === "Offline" ? (
          <>
            <Col sm="4"></Col>
            <Col sm="4">
              <img src={CASH} alt="Cash" />
              <OfflinePayment
                isShow={props.isShow}
                selectedPlan={selectedPlan}
                totalPayableAmount={
                  props.formData.service_type === "OTT" && props.formData.OTT_total_plan_cost
                    ? parseFloat(props.formData.OTT_total_plan_cost).toFixed(0)
                    : props.formData.service_type === "COMBO" &&
                      props.formData.combo_total_plan_cost
                      ? Number(
                        parseFloat(props.formData.combo_total_plan_cost).toFixed(0)
                      ) +
                      (parseFloat(props.formData?.installation_charges) || 0) +
                      (parseFloat(props.formData?.security_deposit) || 0) +
                      (parseFloat(props.formData?.static_ip_cost) || 0)
                      : parseFloat(basedOnstatic).toFixed(0)
                }

                formData={props.formData}
                staticIPCost={props.staticIPCost}
                istelShow={istelShow}
                iswhatsShow={iswhatsShow}
                isEmailShow={isEmailShow}
                totalAmountCal={props.totalAmountCal}
              />
            </Col>
          </>
        ) : paymentRadiovalue === "send to phone" ? (
          <>
            <Col sm="6">
              <PaymentList
                priorCheck={props.priorCheck}
                setSelectedPaymentId={setSelectedPaymentId}
                setShowPayment={setShowPayment}
                showPayment={showPayment}
                submitdata={submitdata}
                formData={props.formData}
              />
            </Col>
            <Col sm="6"></Col>
          </>
        ) : (
          ""
        )}
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    formData,
    errors,
    showImageUploadModal,
    customer_images,
    selectedPlan,
  } = state.KYCForm;
  return {
    errors,
    formData,
    showImageUploadModal,
    customer_images,
    selectedPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFormErrors: (payload) => dispatch(setFormErrors(payload)),
    toggleImageUploadModal: () => dispatch(toggleImageUploadModal()),
    handleChangeFormInput: (payload) =>
      dispatch(handleChangeFormInput(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormStep10);
